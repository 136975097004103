<template>
  <div>
      <h1 class="text-xl">Happy Birthday to my bookworm wife!</h1>
      <p class="text-lg">This is the extreme beta v0.1 of something I thought might be a little more exciting than a spreadsheet, but just as if not more useful. Enjoy!</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>